.Home {
  text-align: center;
}

.container {
  max-width: 492px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.textContainer {
  background: #f2f2f3;
}

.bodyText {
  white-space: break-spaces;
  margin-top: 30px;
}

.emailInput {
  width: 100%;
  max-width: 350px;
  height: 38px;
  padding: 7px 7px 6px 5px;
  border-radius: 8px;
  border: solid 1px #9dd4cb;
  text-align: center;
  font-size: 16px;

  &:focus {
    outline: none;
    border: solid 2px #9dd4cb;

  }
}

.registerForm {

}

.errorMessage {
  color: #dd0000;
}


.bottomFormRow {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  display: flex;
  align-items: center;

  .checkbox-wrapper {
    flex-grow: 1;
    margin-right: 8px;
  }

  .privacyLink {
    text-decoration: underline;
  }

  .submitButton {
    flex-shrink: 0;
    width: 66px;
    height: 38px;
  }
}

.checkbox-wrapper {
  label {
    display: flex;
    align-items: center;


    p {
      text-align: left;
      font-size: 12px;
    }
  }

  .checkboxInput {
    flex-shrink: 0;
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */

    width: 20px;
    height: 20px;
    border-radius: 3px;
    border: solid 1px #9dd4cb;

    margin-right: 12px;
    outline: none;
    cursor: pointer;

    &.checked {
      background-color: #9dd4cb;
      position: relative;

      &::before {
        content: "";
      }
    }

  }
}