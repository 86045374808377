.button {
  padding: 10px 0;
  border-radius: 8px;
  border: solid 1px #9dd4cb;
  background-color: #9dd4cb;
  font-weight: 900;
  font-size: 16px;
  transition: opacity 200ms ease-in;

  &:disabled {
    opacity: 0.6;
  }
}
