$font-size-base: 16px;
$default-text-color: #333;
*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

html {
  color: $default-text-color;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  line-height: normal;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body, h1, h2, h3, h4, h5, h6, p, i, blockquote, pre, dl, dd, form, table, th, td, caption, hr, figure {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;
  font-weight: 900;

  @media (min-width: 600px) {
    font-size: 36px;
  }
}

h2 {
  font-size: 18px;
  font-weight: 900;

  @media (min-width: 600px) {
    font-size: 24px;
  }
}

button, a {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;

  &:focus {
    outline: none !important;
  }
}

p, div, span {
  font-size: $font-size-base;
}

a {
  text-decoration: none;
  color: $default-text-color;
}

html {
  font-size: $font-size-base;
}

body {
  -webkit-tap-highlight-color: transparent;
}

