.App {

}

.coverImage {
  height: 119vw;
  width: 100%;

  background-image: url('/assets/bubbbla-cover-mobile.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (min-width: 600px) {
    height: 43vw;
    width: 100%;
    background-image: url('/assets/bubbbla-cover.jpg');
  }
}

.bubbblaLogo {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 42px;
}
